import { useState, useEffect } from "react";
import { onValue, ref } from "firebase/database";
import { db } from "../FirebaseConfig";

// Custom hook to fetch and process sales data
export const useSalesData = () => {
  const [saleItems, setSaleItems] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    const salesRef = ref(db, "/sales");
    onValue(salesRef, (snapshot) => {
      const salesData = snapshot.val();
      if (salesData) {
        const soldItems = Object.values(salesData)
          .filter((data) => Array.isArray(data.cartItems))
          .flatMap((data) =>
            data.cartItems.map((item) => ({
              ...item,
              paymentReference: data.paymentReference, // Add paymentReference to each item
              customerName: `${data.firstName} ${data.lastName}`, // Add customer name
              time: data.time, // Add purchase time
              date: data.date, // Add purchase date
              totalAmount: data.totalAmount, // Add total amount
              // status: data.status,
            }))
          );
        setSaleItems(soldItems.reverse());

        const seenUids = new Set();
        const customerDetails = Object.values(salesData)
          .filter(
            (data) => data.email && data.firstName && data.lastName && data.uid
          )
          .filter((data) => {
            if (seenUids.has(data.uid)) {
              return false;
            }
            seenUids.add(data.uid);
            return true;
          })
          .map((data) => ({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            uid: data.uid,
            paymentReference: data.paymentReference,
            telephone: data.telephone,
            time: data.time,
            totalAmount: data.totalAmount,
          }));
        setCustomerDetails(customerDetails);

        const totalPrice = soldItems.reduce(
          (total, sale) => total + parseFloat(sale.totalPrice || 0),
          0
        );
        setTotalRevenue(totalPrice);
      }
    });
  }, []);

  return { saleItems, customerDetails, totalRevenue };
};

// Custom hook to fetch user data
export const useUserData = () => {
  const [usersDetails, setUsersDetails] = useState([]);

  useEffect(() => {
    const userRef = ref(db, "/users");
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData) {
        setUsersDetails(Object.values(userData).filter((user) => user));
      }
    });
  }, []);

  return usersDetails;
};

// import { useState, useEffect } from "react";
// import { onValue, ref } from "firebase/database";
// import { db } from "../FirebaseConfig";

// const filterDataByDate = (data, period) => {
//   const today = new Date();
//   const startDate = new Date(today);

//   switch (period) {
//     case "Today":
//       startDate.setHours(0, 0, 0, 0);
//       break;
//     case "This Month":
//       startDate.setDate(1);
//       break;
//     case "This Year":
//       startDate.setMonth(0, 1);
//       break;
//     default:
//       return data;
//   }

//   return data.filter((item) => new Date(item.time) >= startDate);
// };

// export const useSalesData = (period) => {
//   const [saleItems, setSaleItems] = useState([]);
//   const [customerDetails, setCustomerDetails] = useState([]);
//   const [totalRevenue, setTotalRevenue] = useState(0);

//   useEffect(() => {
//     const salesRef = ref(db, "/sales");
//     onValue(salesRef, (snapshot) => {
//       const salesData = snapshot.val();
//       if (salesData) {
//         const soldItems = Object.values(salesData)
//           .filter((data) => Array.isArray(data.cartItems))
//           .flatMap((data) => data.cartItems);

//         const filteredItems = filterDataByDate(soldItems, period);
//         setSaleItems(filteredItems);

//         const customerDetails = Object.values(salesData)
//           .filter(
//             (data) => data.email && data.firstName && data.lastName && data.uid
//           )
//           .map((data) => ({
//             email: data.email,
//             firstName: data.firstName,
//             lastName: data.lastName,
//             uid: data.uid,
//             paymentReference: data.paymentReference,
//             telephone: data.telephone,
//             time: data.time,
//             totalAmount: data.totalAmount,
//           }));

//         const filteredCustomers = filterDataByDate(customerDetails, period);
//         setCustomerDetails(filteredCustomers);

//         const totalPrice = filteredItems.reduce(
//           (total, sale) => total + parseFloat(sale.price || 0),
//           0
//         );
//         setTotalRevenue(totalPrice);
//       }
//     });
//   }, [period]);

//   return { saleItems, customerDetails, totalRevenue };
// };

// // Custom hook to fetch user data
// export const useUserData = () => {
//   const [usersDetails, setUsersDetails] = useState([]);

//   useEffect(() => {
//     const userRef = ref(db, "/users");
//     onValue(userRef, (snapshot) => {
//       const userData = snapshot.val();
//       if (userData) {
//         setUsersDetails(Object.values(userData).filter((user) => user));
//       }
//     });
//   }, []);

//   return usersDetails;
// };
