import React from "react";
import EditProduct from "./editProduct/EditProduct";
import "./EditAddProductModal.css";
import AddProduct from "./addProduct/AddProduct";

export default function EditAddProductModal({
  productDetails,
  setIsEditModal,
  categoriesWithSubcategories,
  isAddProductModal,
  setIsAddProductModal,
  products,
}) {
  return (
    <div className="edit-add-product-modal">
      <div className="container d-flex justify-content-center align-items-center h-100 ">
        {!isAddProductModal ? (
          <EditProduct
            categoriesWithSubcategories={categoriesWithSubcategories}
            productDetails={productDetails}
            setIsEditModal={setIsEditModal}
            products={products}
          />
        ) : (
          <AddProduct
            setIsAddProductModal={setIsAddProductModal}
            categoriesWithSubcategories={categoriesWithSubcategories}
            products={products}
          />
        )}
      </div>
    </div>
  );
}
