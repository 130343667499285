import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../../redux/features/userSlice";
import { signOut } from "firebase/auth";
import Swal from "sweetalert2";

import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo512.png";
import { auth } from "../../FirebaseConfig";
import { useSalesData } from "../../hooks/useFetchData";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import WindowIcon from "@mui/icons-material/Window";

export default function Header({ updateViewState, isViewActive }) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { saleItems } = useSalesData();

  const newSales = saleItems.filter((item) => item.status === "In Progress");

  const handleLogout = async () => {
    try {
      dispatch(logout());
      await signOut(auth);

      Swal.fire({
        icon: "success",
        title: "Logged Out",
        text: "You have successfully logged out.",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Logout Failed",
        text: error.message,
      });
    }
  };

  return (
    <header className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <Link
          to="/"
          className="logo d-flex align-items-center"
          onClick={() => updateViewState("isDashboardViewActive", true)}
        >
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <NavLink
            to="/"
            icon={<WindowIcon />}
            label="Products"
            isActive={isViewActive.isProductsViewActive}
            updateViewState={() =>
              updateViewState(
                isViewActive.isProductsViewActive
                  ? "isDashboardViewActive"
                  : "isProductsViewActive",
                true
              )
            }
          />

          <NavLink
            to="/"
            icon={<NotificationsNoneOutlinedIcon />}
            label="Sales"
            badgeCount={newSales.length}
            isActive={isViewActive.isSalesViewActive}
            updateViewState={() =>
              updateViewState(
                isViewActive.isSalesViewActive
                  ? "isDashboardViewActive"
                  : "isSalesViewActive",
                true
              )
            }
          />

          <li className="nav-item dropdown pe-3">
            <div className="nav-link" data-bs-toggle="dropdown">
              <div className="nav-profile d-flex align-items-center">
                <img
                  src={logo2}
                  alt="Profile"
                  className="rounded-circle profile-img"
                />
              </div>
              <p>{user?.displayName?.split(" ")[0]}</p>
            </div>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{user?.displayName}</h6>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <div
                  className="dropdown-item d-flex align-items-center justify-content-evenly"
                  onClick={handleLogout}
                >
                  {user ? (
                    <>
                      <ExitToAppIcon />
                      <span>Sign Out</span>
                    </>
                  ) : (
                    <>
                      <LoginIcon />
                      <span>Login</span>
                    </>
                  )}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}

function NavLink({ to, icon, label, badgeCount, updateViewState }) {
  return (
    <Link
      to={to}
      className="nav-link nav-icon d-flex flex-column align-items-center"
      onClick={updateViewState}
    >
      {badgeCount !== undefined && (
        <span className="badge badge-number">{badgeCount}</span>
      )}
      {icon}
      <p>{label}</p>
    </Link>
  );
}
