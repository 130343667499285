import { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Sales from "./components/report/sales/Sales";
// import Sidebar from "./components/sidebar/Sidebar";
import Dashboard from "./pages/dashboard/Dashboard";
import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Registration from "./pages/registration/Registration";
import { selectUser } from "./redux/features/userSlice";

function App() {
  const [isViewActive, setIsViewActive] = useState({
    isDashboardViewActive: true,
    isSalesViewActive: false,
    isProductsViewActive: false,
  });
  const updateViewState = (key, value) => {
    setIsViewActive((prevState) => ({
      ...Object.keys(prevState).reduce(
        (acc, k) => ({ ...acc, [k]: false }),
        {}
      ),
      [key]: value,
    }));
  };
  const user = useSelector(selectUser);

  const [productsDetails, setProductsDetails] = useState(null);
  const [categoriesWithSubcategories, setCategoriesWithSubcategories] =
    useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);

  const authorizedUsers = process.env.REACT_APP_AUTHORIZED_USERS.split(",");
  return (
    <div className="App">
      <BrowserRouter>
        {user && authorizedUsers.includes(user.email) && (
          <Header
            updateViewState={updateViewState}
            isViewActive={isViewActive}
          />
        )}
        {/* <Sidebar
          updateViewState={updateViewState}
        /> */}
        <Routes>
          <Route
            path="/"
            element={
              user && authorizedUsers.includes(user.email) ? (
                <Home isViewActive={isViewActive} />
              ) : (
                <Registration />
              )
            }
          />
          <Route
            path="/shop/product/:category/:subcategory?/:id"
            element={
              <Products
                productsDetails={productsDetails}
                setProductsDetails={setProductsDetails}
                categoriesWithSubcategories={categoriesWithSubcategories}
                setCategoriesWithSubcategories={setCategoriesWithSubcategories}
                setSelectedCategory={setSelectedCategory}
                // viewState={viewState}
                updateViewState={updateViewState}
                selectedCategory={selectedCategory}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
