import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { useSalesData } from "../../../hooks/useFetchData"; // Adjust the import path

import "./ReportGraph.css";

export default function ReportGraph() {
  const { saleItems, customerDetails, totalRevenue } = useSalesData();
  useEffect(() => {
    // Initialize ApexCharts after component mounts
    const chart = new ApexCharts(document.querySelector("#reportsChart"), {
      series: [
        {
          name: "Sales",
          data: saleItems.map((item) => item.quantity || 0),
        },
        {
          name: "Revenue",
          data: saleItems.map((item) => item.price || 0),
        },
        {
          name: "Customers",
          data: customerDetails.map((item, idx) => idx + 1 || 0),
        },
      ],
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 4,
      },
      colors: ["#4154f1", "#2eca6a", "#ff771d"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "datetime",
        categories: saleItems.map((item) => {
          const [day, month, year] = item.date.split("/");
          const isoDate = `${year}-${month}-${day}`;
          return `${isoDate}T${item.time}.000Z`;
        }),
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    });

    chart.render();

    // Cleanup on unmount
    return () => {
      chart.destroy();
    };
  }, [saleItems, customerDetails]); // Re-run the chart rendering when saleItems or customerDetails change

  return (
    <div className="card">
      <div className="filter">{/* Removed the filter UI */}</div>

      <div className="card-body">
        <h5 className="card-title">Reports</h5>
        <div id="reportsChart"></div>
      </div>
    </div>
  );
}
