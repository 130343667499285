import React, { useState } from "react";
import Sales from "../../components/report/sales/Sales";
import Dashboard from "../dashboard/Dashboard";
import Products from "../products/Products";

export default function Home({ isViewActive }) {
  const [productsDetails, setProductsDetails] = useState(null);
  const [categoriesWithSubcategories, setCategoriesWithSubcategories] =
    useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <>
      {isViewActive.isDashboardViewActive && <Dashboard />}
      {isViewActive.isSalesViewActive && <Sales />}
      {isViewActive.isProductsViewActive && (
        <Products
          productsDetails={productsDetails}
          setProductsDetails={setProductsDetails}
          categoriesWithSubcategories={categoriesWithSubcategories}
          setCategoriesWithSubcategories={setCategoriesWithSubcategories}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
      )}
    </>
  );
}
