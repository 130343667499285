import React, { useEffect, useState } from "react";
import "./AddProduct.css";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { get, ref, update } from "firebase/database";
import { db } from "../../../../FirebaseConfig";
import Swal from "sweetalert2";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import TextareaAutosize from "react-textarea-autosize";

export default function AddProduct({
  setIsAddProductModal,
  categoriesWithSubcategories,
  products,
}) {
  const [editedProductDetails, setEditedProductDetails] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [productImageFile, setProductImageFile] = useState(null);
  const [productImageUrl, setProductImageUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]); // To store newly selected images

  useEffect(() => {
    if (Object.keys(categoriesWithSubcategories).length > 0) {
      const firstCategory = Object.keys(categoriesWithSubcategories)[0];
      setSelectedCategory(firstCategory);

      // Set the subcategory to the first subcategory or an empty string
      const subCategories = categoriesWithSubcategories[firstCategory];
      setSelectedSubCategory(subCategories?.[0] || "");
    }
  }, [categoriesWithSubcategories]);

  const handleProductDetailsChange = (field, value) => {
    setEditedProductDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);

    // Set the subcategory to the first subcategory or an empty string
    const subCategories = categoriesWithSubcategories[newCategory];
    setSelectedSubCategory(subCategories?.[0] || "");
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const handleRemoveImageFromSelectedImages = (indexToDelete) => {
    setSelectedImages((prev) =>
      prev.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        Swal.fire(
          "Error",
          "Image file is too large. Max size is 5MB.",
          "error"
        );
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire(
          "Error",
          "Invalid file type. Only JPEG, JPG, and PNG are allowed.",
          "error"
        );
        return;
      }

      setSelectedImages((prev) => [...prev, file]); // Add new image to selectedImages array
    }
  };

  const handleAddMorePhotos = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(
      (file) =>
        file.size <= 5 * 1024 * 1024 &&
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
    );

    if (validFiles.length < files.length) {
      Swal.fire(
        "Error",
        "Some files were invalid or too large. Only valid files were added.",
        "error"
      );
    }

    setSelectedImages((prev) => [...prev, ...validFiles]);
  };

  const handleSaveProduct = async () => {
    if (!editedProductDetails.name) {
      Swal.fire({
        icon: "error",
        title: "Missing Fields",
        text: "Please make sure to fill in the product name.",
      });
      return;
    }

    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true);

    const categoryIndex = Object.keys(categoriesWithSubcategories).indexOf(
      selectedCategory
    );

    const subcategoryIndex =
      categoriesWithSubcategories[selectedCategory]?.indexOf(
        selectedSubCategory
      );

    // Determine the index for the new product
    const productIndex =
      selectedSubCategory && subcategoryIndex !== undefined
        ? products[categoryIndex]?.products[subcategoryIndex]?.products
            ?.length || 0
        : products[categoryIndex]?.products?.length || 0;

    let basePath =
      subcategoryIndex !== null &&
      subcategoryIndex !== undefined &&
      subcategoryIndex !== -1
        ? `products/${categoryIndex}/products/${subcategoryIndex}/products/${productIndex}`
        : `products/${categoryIndex}/products/${productIndex}`;

    try {
      // Upload images if any were added
      Swal.fire({
        title: "Uploading Images...",
        html: "Please wait while the images are being uploaded.",
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
        didOpen: () => {
          const swalModal = document.querySelector(".swal2-container");
          if (swalModal) {
            swalModal.style.zIndex = "9999"; // Adjust z-index here
          }
        },
      });

      const uploadedImageUrls = await Promise.all(
        selectedImages.map(async (image) => {
          const formData = new FormData();
          formData.append("file", image);
          formData.append(
            "upload_preset",
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
          );
          formData.append(
            "cloud_name",
            process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
          );

          const response = await axios.post(
            `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
            formData
          );
          return response.data.secure_url;
        })
      );

      const newProduct = {
        ...editedProductDetails,
        images: uploadedImageUrls,
        id: productIndex,
      };

      await update(ref(db, `${basePath}`), newProduct);

      Swal.fire({
        icon: "success",
        title: "Product Added Successfully",
        text: "The product has been added to the selected category and subcategory.",
      });

      setIsAddProductModal(false); // Close the modal after successful submission
    } catch (error) {
      console.error("Error adding product:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `There was an error adding the product. ${error.message}`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setIsAddProductModal(false);
  };

  return (
    <div className="add-product">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="images d-flex align-items-center justify-content-around">
            {/* Render newly selected images */}
            {selectedImages.map((img, index) => (
              <div
                key={`new-${index}`}
                className={`image-div`}
                style={{
                  backgroundImage: `url(${URL.createObjectURL(img)})`,
                }}
              >
                <div className="update-photo">
                  {/* <label htmlFor={`upload-photo-${productIndex}-${index}`}>
                    <AddAPhotoIcon className="add-photo-icon" />
                    <input
                      type="file"
                      id={`upload-photo-${productIndex}-${index}`}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </label> */}
                  <button
                    className="delete-image-btn"
                    onClick={() => handleRemoveImageFromSelectedImages(index)}
                  >
                    <DeleteIcon className="delete-photo-icon" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="add-more-photos">
            <label htmlFor="add-more-photos">
              <AddToPhotosIcon className="add-more-photo-icon" />
              <input
                type="file"
                id="add-more-photos"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={handleAddMorePhotos}
              />
            </label>
          </div>

          <div className="details-grid">
            {/* Product Name */}
            <div className="detail-row">
              <div className="detail-title">Product Name</div>
              <input
                className="detail-value"
                value={editedProductDetails.name || ""}
                placeholder={"Name of the product"}
                onChange={(e) =>
                  handleProductDetailsChange("name", e.target.value)
                }
                required
              />
            </div>

            {/* Category Dropdown */}
            <div className="detail-row">
              <div className="detail-title">Category</div>
              <select
                className="detail-value"
                value={selectedCategory}
                onChange={handleCategoryChange}
                required
              >
                {Object.keys(categoriesWithSubcategories).map(
                  (category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  )
                )}
              </select>
            </div>

            {/* Subcategory Dropdown */}
            {selectedSubCategory && (
              <div className="detail-row">
                <div className="detail-title">Subcategory</div>
                <select
                  className="detail-value"
                  value={selectedSubCategory}
                  onChange={handleSubCategoryChange}
                >
                  {categoriesWithSubcategories[selectedCategory]?.map(
                    (subCategory, index) => (
                      <option key={index} value={subCategory}>
                        {subCategory || "No Subcategory"}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
            <div className="detail-row">
              <div className="detail-title">Description</div>
              <TextareaAutosize
                value={editedProductDetails.description}
                className="detail-value"
                onChange={(e) =>
                  handleProductDetailsChange("description", e.target.value)
                }
                placeholder="Description"
                minRows={3}
              />
            </div>

            {/* Price */}
            <div className="detail-row">
              <div className="detail-title">Price</div>
              <input
                className="detail-value"
                value={editedProductDetails.price || ""}
                placeholder={"0 KES"}
                onChange={(e) =>
                  handleProductDetailsChange("price", e.target.value)
                }
              />
            </div>
          </div>

          <div className="buttons">
            <button onClick={handleCancel} className="cancel-btn">
              Cancel
            </button>
            <button
              onClick={handleSaveProduct}
              className="save-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Add New Product"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
