import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyBFvFudm_Xq0KS0I_P48DIGEAJOggKogjY",
  authDomain: "vertex-7fc3e.firebaseapp.com",
  databaseURL: "https://vertex-7fc3e-default-rtdb.firebaseio.com",
  projectId: "vertex-7fc3e",
  storageBucket: "vertex-7fc3e.firebasestorage.app",
  messagingSenderId: "622164859303",
  appId: "1:622164859303:web:83e04935d473d2805abe82",
  measurementId: "G-VQ7HL3R9JK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const database = getFirestore();
export const db = getDatabase(app); // Initialize the Realtime Database
export const storage = getStorage(app); // Initialize Firebase Storage
