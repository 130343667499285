import React from "react";
import "./InfoCard.css";

export default function InfoCard({
  title,
  amount,
  percentage,
  direction,
  Icon,
}) {
  return (
    <div className="col-lg-3 col-md-6">
      <div
        className={`card info-card ${
          title === "Revenue" ? "revenue-card" : ""
        }${title === "Sales" ? "sales-card" : ""}${
          title === "Customers" ? "customers-card" : ""
        }${title === "Users" ? "user-card" : ""}`}
      >
        <div className="filter">
          <a className="icon" href="#" data-bs-toggle="dropdown">
            <i className="bi bi-three-dots"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>

            <li>
              <a className="dropdown-item" href="#">
                Today
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                This Month
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                This Year
              </a>
            </li>
          </ul>
        </div>

        <div className="card-body d-flex flex-column justify-content-between">
          <h5 className="card-title">
            {title} <span>{/* | This Month */}</span>
          </h5>

          <div className="d-flex align-items-center">
            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
              {Icon && <Icon />}
            </div>
            <div className="ps-2">
              <h6>{amount}</h6>
              {/* <span className="text-success small pt-1 fw-bold">
                {percentage}%
              </span> */}
              {/* <span className="text-muted small pt-2 ps-1">{direction}</span> */}
              <span className="text-muted small pt-2 ps-1">{title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
