import React, { useState } from "react";
import { get, onValue, ref, update } from "firebase/database";
import Swal from "sweetalert2";
import { db } from "../../../FirebaseConfig";
import { useSalesData } from "../../../hooks/useFetchData";
import "./Sales.css";

export default function Sales() {
  const { saleItems, customerDetails } = useSalesData();
  const [hoveredRow, setHoveredRow] = useState(null);

  const updateStatus = async (itemIndex, paymentReference, newStatus) => {
    try {
      const salesDataSnapshot = await get(ref(db, `/sales`));
      const salesData = salesDataSnapshot.val();

      const saleKey = Object.keys(salesData).find(
        (key) => salesData[key].paymentReference === paymentReference
      );

      if (!saleKey) throw new Error("Payment reference not found.");

      const saleRef = ref(db, `/sales/${saleKey}/cartItems/${itemIndex}`);
      await update(saleRef, { status: newStatus });

      Swal.fire({
        icon: "success",
        title: "Status Updated",
        text: `Status has been updated to ${newStatus}.`,
      });
    } catch (error) {
      console.error("Error updating status:", error);
      Swal.fire({
        icon: "error",
        title: "Update Failed",
        text: "There was an error updating the status. Please try again.",
      });
    }
  };

  return (
    <div className="sales-container">
      <div className="card recent-sales">
        <div className="filter">
          <a className="icon" href="#" data-bs-toggle="dropdown">
            <i className="bi bi-three-dots"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end">
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Today
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                This Month
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                This Year
              </a>
            </li>
          </ul>
        </div>

        <div className="card-body">
          <h5 className="card-title">
            Sales <span>| Today</span>
          </h5>

          <table className="table table-responsive table-striped">
            <thead>
              <tr>
                <th>Reference No.</th>
                <th>Preview</th>
                <th>Customer</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {saleItems.map((item, index) => {
                const customer = customerDetails.find(
                  (cust) => cust.paymentReference === item.paymentReference
                );
                return (
                  <tr
                    key={index}
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <td>
                      <a href="#">#{item.paymentReference}</a>
                    </td>
                    <td>
                      <img
                        src={item.image}
                        alt="Product"
                        className="preview-img"
                      />
                    </td>
                    <td>{item?.customerName || "Unknown"}</td>
                    <td>
                      <a href="#" className="text-primary">
                        {item.name}
                      </a>
                    </td>
                    <td>{item.quantity}</td>
                    <td>Ksh {item.totalPrice}</td>
                    <td>
                      {hoveredRow === index ? (
                        <select
                          className="form-select"
                          defaultValue={item.status}
                          onChange={(e) => {
                            const newStatus = e.target.value;
                            if (newStatus !== item.status) {
                              Swal.fire({
                                title: "Confirm Update",
                                text: `Update status from ${item.status} to ${newStatus}?`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, update it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  updateStatus(
                                    index,
                                    item.paymentReference,
                                    newStatus
                                  );
                                }
                              });
                            }
                          }}
                        >
                          <option value="Delivered">Delivered</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Returned">Returned</option>
                        </select>
                      ) : (
                        <span
                          className={`badge badge-${item.status
                            .toLowerCase()
                            .replace(" ", "-")}`}
                        >
                          {item.status}
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
