import React from "react";
import "./SubHeader.css";

export default function SubHeader({ title, subheader, align }) {
  return (
    <div className="section-subheader" style={align && { textAlign: "center" }}>
      <h2>{title}</h2>
      <h3>{subheader}</h3>
    </div>
  );
}
