import React from "react";
import "./Dashboard.css";
import PageTitle from "../../components/pagetitle/PageTitle";
import InfoCard from "../../components/infoCard/InfoCard";
import Budget from "../../components/report/budget/Budget";
import WebsiteTraffics from "../../components/report/websiteTraffic/WebsiteTraffics";
import ReportGraph from "../../components/report/reportGraph/ReportGraph";
import RecentActivities from "../../components/report/recentActivities/RecentActivities";
import Sales from "../../components/report/sales/Sales";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import Groups2Icon from "@mui/icons-material/Groups2";
import { useSalesData, useUserData } from "../../hooks/useFetchData"; // Import custom hooks

export default function Dashboard() {
  const { saleItems, customerDetails, totalRevenue } = useSalesData(); // Using custom hook for sales data
  const usersDetails = useUserData(); // Using custom hook for user data

  const infoCardData = [
    {
      title: "Users",
      amount: usersDetails.length,
      percentage: "-12",
      direction: "descrease",
      Icon: Groups2Icon,
    },
    {
      title: "Customers",
      amount: customerDetails.length,
      percentage: "-12",
      direction: "descrease",
      Icon: PeopleAltOutlinedIcon,
    },
    {
      title: "Sales",
      amount: saleItems.length,
      percentage: "12",
      direction: "increase",
      Icon: ShoppingCartOutlinedIcon,
    },
    {
      title: "Revenue",
      amount: totalRevenue,
      percentage: "8",
      direction: "increase",
      Icon: AccountBalanceOutlinedIcon,
    },
  ];

  return (
    <main id="main" className="main">
      <PageTitle />
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              {infoCardData.map((card, index) => (
                <InfoCard
                  key={index}
                  title={card.title}
                  amount={card.amount}
                  percentage={card.percentage}
                  direction={card.direction}
                  Icon={card.Icon}
                />
              ))}
              <div className="col-12">
                <ReportGraph />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            {/* <RecentActivities /> */}
            <Budget />
            <WebsiteTraffics />
          </div>
        </div>
        <div className="col-12">
          <Sales />
        </div>
      </section>
    </main>
  );
}
