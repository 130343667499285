import GoogleButton from "react-google-button";
import "./Registration.css";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../../FirebaseConfig";
import { useDispatch } from "react-redux";
import { login } from "../../redux/features/userSlice";
import { onValue, ref, set } from "firebase/database";
import logo from "../../assets/images/logo_no_bg.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;

        if (user) {
          // Get the list of authorized emails from environment variables
          const authorizedUSERS =
            process.env.REACT_APP_AUTHORIZED_USERS.split(",");

          // Check if the user is authorized
          if (!authorizedUSERS.includes(user.email)) {
            Swal.fire({
              icon: "error",
              title: "Unauthorized",
              text: "You are not authorized to sign in.",
              didOpen: () => {
                const swalModal = document.querySelector(".swal2-container");
                if (swalModal) {
                  swalModal.style.zIndex = "9999"; // Adjust z-index here
                }
              },
            });
            return; // Stop further execution
          }

          const userRef = ref(db, "users/" + user?.uid);

          onValue(userRef, (snapshot) => {
            const userData = snapshot?.val();

            if (userData) {
              // Update Redux and Navigate
              dispatch(
                login({
                  displayName: userData?.displayName || "",
                  photoURL: userData?.photoURL || "",
                  email: userData?.email || "",
                  uid: userData?.uid || "",
                  phoneNumber: userData?.phoneNumber || "",
                })
              );

              navigate("/");
            } else {
              // New user case
              set(userRef, {
                displayName: user?.displayName || "",
                photoURL: user?.photoURL || "",
                email: user?.email || "",
                uid: user?.uid || "",
                phoneNumber: user?.phoneNumber || "",
              });

              dispatch(
                login({
                  displayName: user?.displayName || "",
                  photoURL: user?.photoURL || "",
                  email: user?.email || "",
                  uid: user?.uid || "",
                  phoneNumber: user?.phoneNumber || "",
                })
              );

              navigate("/");
            }
          });
        }
      })
      .catch((error) => {
        console.error("Google Sign In Error:", error);
      });
  };

  const handleGoogleSignIn = async () => {
    try {
      googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="registration">
      <div className="reg-container">
        <div className="reg-div">
          <div className={`registration-div`}>
            <img
              src={logo}
              className="reg-logo"
              alt="vertex textiles and hardware"
            />
            <h2 className="sub-header-reg">
              Welcome to Vertex Textiles and Hardware LTD
            </h2>
            <GoogleButton onClick={handleGoogleSignIn} className="google-btn" />
            <p>
              By Signing up as{" "}
              <span style={{ color: "#00b7b2", fontWeight: "600" }}>user</span>,
              you agree to our <a href="https://vibesandvent.org">Terms</a>,{" "}
              <a href="https://vibesandvent.org">Privacy</a> and{" "}
              <a href="https://vibesandvent.org">Cookie</a> use.
            </p>
            <p>Welcome to Vertex Textiles and Hardware LTD.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
