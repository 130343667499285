import React from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";
import { useDispatch, useSelector } from "react-redux";
import { login, selectUser } from "../../../redux/features/userSlice";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";

export default function ProductCard({
  category,
  index,
  subCategory,
  id,
  name,
  images,
  price,
  updateViewState,
}) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  // Check if the item exists in the cart
  const isInCart = user?.cart?.some(
    (item) => item.id === id && item.name === name && item.category === category
  );

  return (
    <div
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      className="product-card"
      key={index}
    >
      <Link
        to={`/shop/product/${category.replace(/\s+/g, "-")}${
          subCategory ? `/${subCategory.replace(/\s+/g, "-")}` : ""
        }/${name.replace(/\s+/g, "-")}-${id}`}
      >
        <div className="product-card-image-div">
          <img
            className="product-card-image"
            src={`${images ? images[0] : ""}`}
          />
        </div>
      </Link>
      <Link
        to={`/shop/product/${category.replace(/\s+/g, "-")}${
          subCategory ? `/${subCategory.replace(/\s+/g, "-")}` : ""
        }/${name.replace(/\s+/g, "-")}-${id}`}
        className="link"
      >
        <div className="product-card-footer">
          <p className="product-card-name" style={{ textDecoration: "none" }}>
            {name.length > 20 ? `${name.slice(0, 20)}...` : name}
          </p>
          <p className="product-card-price">KES {price}</p>
        </div>
      </Link>
    </div>
  );
}
