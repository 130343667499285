import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Product from "./product/Product";
import "./Products.css";
import { auth, db } from "../../FirebaseConfig";
import { onValue, ref } from "firebase/database";
import EditAddProductModal from "./editAndAdd/EditAddProductModal";
import ProductCard from "./productCard/ProductCard";

export default function Products({
  productsDetails,
  setProductsDetails,
  categoriesWithSubcategories,
  setCategoriesWithSubcategories,
  selectedCategory,
  setSelectedCategory,
  updateViewState,
}) {
  const { category, subcategory, id } = useParams(); // Get product ID from URL

  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered products

  const [isEditModal, setIsEditModal] = useState(false);
  const [isAddProductModal, setIsAddProductModal] = useState(false);

  useEffect(() => {
    const starCountRef = ref(db, "products");

    // Fetching the data from Firebase
    onValue(
      starCountRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setProducts(data);
          setProductsDetails(getAllProducts(data));
        } else {
          console.log("No data available");
        }
      },
      (error) => console.log("Error fetching data:", error)
    );
  }, []);
  useEffect(() => {
    if (selectedCategory) {
      // Filter products based on selected category or subcategory
      const filtered = productsDetails?.filter((product) =>
        selectedCategory.type === "category"
          ? product.category === selectedCategory.value
          : product.subCategory === selectedCategory.value
      );
      setFilteredProducts(filtered);
    } else {
      // Show all products when no filter is applied
      setFilteredProducts(productsDetails);
    }
  }, [selectedCategory, productsDetails]);

  const getAllProducts = (categories) => {
    const allProducts = [];
    let categoriesWithSubcategoriesTemp = {}; // Temporary object to accumulate categories and subcategories

    categories.forEach((category, categoryIndex) => {
      if (!categoriesWithSubcategoriesTemp[category.category]) {
        categoriesWithSubcategoriesTemp[category.category] = [];
      }

      if (category.products) {
        category.products.forEach((productGroup, subcategoryIndex) => {
          if (productGroup.subCategory && productGroup.products) {
            // Add subcategory to the corresponding category
            if (
              !categoriesWithSubcategoriesTemp[category.category].includes(
                productGroup.subCategory
              )
            ) {
              categoriesWithSubcategoriesTemp[category.category].push(
                productGroup.subCategory
              );
            }

            // Process products within the subcategory
            productGroup.products.forEach((product, productIndex) => {
              allProducts.push({
                ...product,
                category: category.category, // Add category name
                subCategory: productGroup.subCategory, // Add subcategory name
                categoryIndex, // Add index of the category
                subcategoryIndex, // Add index of the subcategory group
                productIndex, // Add index of the product in the subcategory
              });
            });
          } else if (
            !productGroup.subCategory &&
            productGroup.id !== undefined
          ) {
            // Add products directly under the category
            const productIndex = category.products.indexOf(productGroup);

            allProducts.push({
              ...productGroup,
              category: category.category, // Add category name
              subCategory: null, // No subcategory
              categoryIndex, // Add index of the category
              subcategoryIndex: null, // No subcategory group, set as null
              productIndex, // Add index of the product in the category
            });
          }
        });
      }
    });

    // Set categories with subcategories into the state
    setCategoriesWithSubcategories(categoriesWithSubcategoriesTemp);
    // Sort the products alphabetically by the 'name' property
    return allProducts.sort((a, b) => {
      const nameA = a.name ? a.name.toString() : "";
      const nameB = b.name ? b.name.toString() : "";
      return nameA.localeCompare(nameB);
    });
  };

  useEffect(() => {
    if (productsDetails && id) {
      // Preprocess category and subcategory to replace "-" with " "
      const processedCategory = category.replace(/-/g, " ").toLowerCase();
      const processedSubcategory = subcategory
        ? subcategory.replace(/-/g, " ").toLowerCase()
        : null;

      // Extract the ID from the URL
      const productId = id.split("-").pop(); // Get the last part as the ID

      // Locate the product in `productsDetails`
      const productDetail = productsDetails.find((product) => {
        // Ensure all comparisons are case-insensitive
        const isCategoryMatch =
          product.category.toLowerCase() === processedCategory;

        const isSubcategoryMatch =
          processedSubcategory &&
          product.subCategory &&
          product.subCategory.toLowerCase() === processedSubcategory;

        const isProductIdMatch = product.id === parseInt(productId);

        // Check all conditions
        return (
          isCategoryMatch &&
          (!subcategory || isSubcategoryMatch) &&
          isProductIdMatch
        );
      });

      if (productDetail) {
        setProduct(productDetail); // Set the product details
      } else {
        console.log("Product not found.");
      }
    }
  }, [id, category, subcategory, productsDetails]); // Re-run this effect whenever any dependency changes

  if (id && !product) {
    return <div className="not-found">Product not found.</div>; // Show a message if the product doesn't exist
  }

  const isAuthorized = process.env.REACT_APP_AUTHORIZED_USERS?.split(
    ","
  ).includes(auth.currentUser?.email);

  return (
    <div className="products" id="products">
      {(isEditModal || isAddProductModal) && (
        <EditAddProductModal
          productDetails={product}
          setIsEditModal={setIsEditModal}
          setIsAddProductModal={setIsAddProductModal}
          isAddProductModal={isAddProductModal}
          categoriesWithSubcategories={categoriesWithSubcategories}
          products={products}
        />
      )}

      <div className="container d-flex flex-column ">
        {!id && (
          <div className="d-flex justify-content-between mb-4 ">
            {isAuthorized && (
              <button
                className="add-product-btn"
                onClick={() => setIsAddProductModal(true)}
              >
                Add New Product
              </button>
            )}
          </div>
        )}
        {id && (
          <Product
            productDetails={product}
            isEditModal={isEditModal}
            setIsEditModal={setIsEditModal}
            updateViewState={updateViewState}
          />
        )}

        <div className="products-tabs d-flex justify-content-center justify-content-lg-between flex-wrap">
          {!filteredProducts ? (
            <h2 style={{ height: "100vh" }}>Loading...</h2>
          ) : (
            filteredProducts.map(
              ({ category, subCategory, id, name, images, price }, index) => (
                <ProductCard
                  index={index}
                  category={category}
                  subCategory={subCategory}
                  id={id}
                  name={name}
                  images={images}
                  price={price}
                  updateViewState={updateViewState}
                />
              )
            )
          )}
        </div>
      </div>
    </div>
  );
}
